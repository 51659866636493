<template>
  <div class="not-found">
    <h1>404 - Page Not Found</h1>
    <p>The page you are looking for does not exist.</p>
    <a href="/">Go Back to Home</a>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style lang="scss" scoped>
.not-found {
  text-align: center;
  margin: 50px;
  h1, p {
    color: #333;
  }
  a:hover {
    color: #1e73beb2;
  }
}
</style>